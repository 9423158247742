Vue.component('itemViewComponent', {
    methods:{
        goBack:function(){
            this.$router.go(-1);
        }
    },
    template:`<div id="container">
                <headerSectionComponent :items_in_car="$store.items_in_car" ></headerSectionComponent>
                <resetPasswordComponent></resetPasswordComponent>
                <!--<registerComponent></registerComponent>-->
                <loginComponent></loginComponent>
                <div class="section white">
                    <div class="container">        
                        <div class="row">
                            <div class="left sticky-component items-category-tree">
                              <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                            </div>
                            <div class="col m9 s12 l9">
                                <div class="row header-produktliste">
                                      <div class="breadcrumb-container">
                                         <router-link class="breadcrumb" href="#" :to="'/home'"></i> Inicio</router-link>
                                         <router-link v-for="cat in $store.getters.getCategoriesHistory" :key="'cat-link-'+cat.name" class="breadcrumb" :to="'/category/'+encodeURI(cat.name.replace('/',''))">{{cat.name}}</router-link>
                                      </div>
                                      <singleItemComponent :item="$store.getters.getItemByCode($route.params.id)" :container="false"></singleItemComponent>
                                </div>
                            </div>
                            <div class="col m2 s12 l2 sticky-component right">
                                <userSectionComponent></userSectionComponent>
                                <carComponent></carComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
});
